import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Container, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Button, IconButton, Box, Dialog,
  Menu, MenuItem,
} from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CityDialog from '../commonForms/Locations';
import logoImage from '../../assets/images/logo.jpeg'
import Navbar from '../commonForms/Navbar';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import NoImage from "../../assets/images/Thumbnail.jpg"
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppBarComponent from '../commonForms/AppBar';
import { config } from '../../config';




const brands = [
  { name: 'Apple Iphone', image: 'https://logos-world.net/wp-content/uploads/2022/01/iPhone-Logo.jpg' },
  { name: 'Samsung', image: 'https://images.samsung.com/is/image/samsung/assets/global/about-us/brand/logo/256_144_1.png?$512_N_PNG$' },
  {name : "One Plus", image : "https://i2.wp.com/theschedio.com/wp-content/uploads/2020/03/OnePlus_LU_Black_RGB.png?fit=740%2C254&ssl=1"},
  { name: 'Realme', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Realme_logo.svg/2560px-Realme_logo.svg.png' },
  { name: 'OPPO', image: 'https://www.michael-young.com/wp-content/uploads/2020/03/Oppo-Logo.png' },
  { name: 'VIVO', image: 'https://banner2.cleanpng.com/20180412/ysq/kisspng-vivo-logo-smartphone-branding-5acf57274b6e50.967449261523537703309.jpg' },
  { name: 'Xiaomi', image: 'https://madaboutkitchen.in/wp-content/uploads/2021/04/Xiaomi-redmi-logo.jpg' },
  { name: 'Poco', image: 'https://i.pinimg.com/474x/03/49/92/03499271922999145a126e6b8e47f961.jpg' },
  { name: 'IQOO', image: 'https://seeklogo.com/images/I/iqoo-logo-DF5775D738-seeklogo.com.png' },
  { name: 'Nothing', image: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Nothing_Logo.webp' },
  { name: 'Google', image: 'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png' },
  { name: 'Motorola', image: 'https://logolook.net/wp-content/uploads/wordpress-popular-posts/28151-featured-225x127.png' },
  { name: 'NOKIA', image: 'https://static.vecteezy.com/system/resources/previews/020/927/540/non_2x/nokia-logo-brand-phone-symbol-black-design-finland-mobile-illustration-free-vector.jpg' },
  { name: 'ASUS', image: 'https://logos-world.net/wp-content/uploads/2020/07/Asus-Logo.png' },
];
 
const services = [
  { name: 'Sell Phone', image: 'https://i.ibb.co/CwfryvW/1.png' },
  { name: 'Sell Laptop', image: 'https://i.ibb.co/fYVvQ2N/2.png' },
  { name: 'Sell iMac', image: 'https://i.ibb.co/r0DPrg0/3.png' },
  { name: 'Sell Tablet', image: 'https://i.ibb.co/smvyyFR/4.png' },
  { name: 'Sell Earbuds', image: 'https://i.ibb.co/QDVTL6k/5.png' },
  { name: 'Sell Smartwatch', image: 'https://i.ibb.co/QM16yNV/6.png' },
  { name: 'Sell   Camera', image: 'https://i.ibb.co/gMQ0HJs/7.png' },];

  const selling = [  
    { name: 'Repair Phone', image: 'https://i.ibb.co/pJnLHKs/8.png' },
    { name: 'Buy Phone', image: 'https://i.ibb.co/94ZNDSP/9.png' },
    { name: 'Buy Laptop', image: 'https://i.ibb.co/8gtJRNr/10.png' },
    ]





const FrontPage = () => {
  const location = useLocation();
  const name = location.state?.name;
  const email = location.state?.email;
  const phoneNumber = location.state?.phoneNumber;
 
  const navigate = useNavigate();
  const [locationEl, setLocationEl] = React.useState(null);
  const [openCityDialog, setOpenCityDialog] = React.useState(false);  // New state for the dialog
  const [selectedCity, setSelectedCity] = useState('Mysore');

  const [dataFromBackend, setDataFromBackend] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);

 
  useEffect(() => {
    // Simulate initial data fetch or use a function to load data
    fetchData(setSelectedCity, 1);
  }, [setSelectedCity]);

    useEffect(() => {
    if (location.state?.showCityDialog === false) {
      setOpenCityDialog(false);  // Close the dialog only if `showCityDialog` is explicitly set to false
    }
  }, [location.state?.showCityDialog]);

  const fetchData = (City, page) => {
    setLoading(true);
    fetch(`${config.Api}sheet-data?City=${City}&page=${page}&limit=10`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      mode:"no-cors"
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (page === 1) {
        setDataFromBackend(data); // For the first page, replace data
      } else {
        setDataFromBackend([...dataFromBackend, ...data]); // For subsequent pages, append data
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
    });
  };


  const handleCitySelect = (cityName) => {
    fetchData(cityName,1);
    setSelectedCity(cityName);
    // onCitySelect(cityName)
    
  };
  
  
 
const handleLocationClick = (event) => {
    setLocationEl(event.currentTarget);
    setOpenCityDialog(true)
  };

  const handleLocationClose = () => {
    setLocationEl(null);
  };
  const handleCloseCityDialog = () => {
    setOpenCityDialog(false);
  };


  const handleProfileMenuClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

 



  const handleServiceClick = (serviceName) => {
    navigate(`/sell/${serviceName.toLowerCase().replace(' ', '-')}`, { state: { serviceName, data: dataFromBackend, city:selectedCity,page : currentPage, name: name,email:email,phoneNumber:phoneNumber} });
  };
  
  const handleBrandClick = (brand) => {
    if (brand && brand.name) {
      navigate(`/sell/${brand.name.toLowerCase().replace(' ', '-')}`, { 
        state: { brandName: brand.name, data: dataFromBackend, city: selectedCity, page: currentPage, name: name,email:email,phoneNumber:phoneNumber } 
      });
    } else {
    }
  };

 

  const sliderImages = [
    {id:1,image : "https://i.ibb.co/MnN1dpT/1.png", 
    },
    {id:2,image : "https://i.ibb.co/Zhpmc5m/2.png", 
     },
     {id:3,image : "https://i.ibb.co/7QHDRKs/3.png", 
     },
     {id:4,image : "https://i.ibb.co/QpFSpjr/4.png", 
     }
  ];



  return (
    <div>
      {/* AppBar Section */}
       <AppBarComponent userDetails={{name,email,phoneNumber}} onCitySelect={handleCitySelect} selectedCity1={selectedCity}
  />
       
    <Navbar/>
    

       {/* Body Section */}
      <Container style={{ marginTop: '20px' }}>
      <Box 
      sx={{ 
        bgcolor: '#f5f5f5', 
        p: { xs: 1, sm: 2 }, 
        textAlign: 'center', 
        borderRadius: "10px", 
        // height: { xs: 300, sm: 400, md: 500 }  // Adjust the Box height
      }} 
>
  <Carousel 
    autoPlay 
    interval={4000} 
    infiniteLoop 
    showThumbs={false} 
    showStatus={false} 
    dynamicHeight={false} 
  >
    {sliderImages.map((image, index) => (
      <div 
        key={index} 
        style={{ 
          height: '100%',   // Ensure the div takes the full height
          display: 'flex',  // Use flexbox to ensure the image covers the entire space
          justifyContent: 'center', 
          alignItems: 'center'
        }}
      >
        <img 
          src={image ? image.image : NoImage} 
          alt={`slide-${index}`} 
          style={{ 
            height: '100%',    // Ensure the image takes the full height
            width: '100%',     // Ensure the image covers the full width
            objectFit: 'cover' // Ensure the image covers the entire box without distortion
          }} 
        />
      </div>
    ))}
  </Carousel>
</Box>

    <Typography className='lets-get-started' variant="h3" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>Let's get started.. what are you selling today?</Typography>
      <Typography className='lets-get-started' variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}> Grab the best deal on your old device!</Typography>  
      <Box sx={{ marginTop: '30px', textAlign: 'center' }}>

      <Grid container spacing={2} justifyContent="center">
          {services.map((service) => (
             <Grid item xs={3} sm={3} md={3} lg={3} key={service.name} onClick={() => handleServiceClick(service.name)}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={service.name}
                    height="140"
                    image={service.image ? service.image : NoImage}
                    title={service.name}
                    style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}
                  />
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} className='our-services'>
                  {service.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
          </Grid>

          <Typography style={{marginTop:'4%'}} variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem'}, fontWeight:{xs:"700",sm:"700",md:"700" }, marginBottom:{sm:"2%"}}}>Our Services</Typography>

        <Grid container spacing={2} justifyContent="center">
          {selling.map((service) => (
             <Grid item xs={3} sm={3} md={3} lg={3} key={service.name} onClick={() => handleServiceClick(service.name)}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={service.name}
                    height="140"
                    image={service.image ? service.image : NoImage}
                    title={service.name}
                    style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}
                  />
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} className='our-services'>
                  {service.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}

 
 



        </Grid>
        </Box>
        <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem'}, fontWeight:{xs:"700",sm:"700",md:"700" }, marginBottom:{sm:"2%"}}}>Select a Brand</Typography>
     
        <Grid container spacing={2} justifyContent="center">
          {brands.map((brand) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={brand.name} onClick={() => handleBrandClick(brand)}>
              <Card>
              <CardActionArea style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CardMedia
                    component="img"
                    alt={brand.name}
                    height="100"
                    image={brand.image ? brand.image : NoImage}
                    title={brand.name}
                    style={{ objectFit: 'contain', display: 'block', margin: 'auto', maxWidth: '80px' }}

                  />
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                        {/* {brand.name} */}
                      </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Box>
        <FAQ/>
         <Footer/>
      </Container>


       

      <CityDialog
        openCityDialog={openCityDialog}
        handleCloseCityDialog={handleCloseCityDialog}
        setSelectedCity={setSelectedCity}
        onCitySelect={handleCitySelect}
      />




    </div>
  );
};

export default FrontPage;
