import React from 'react';
import { CssBaseline, Container } from '@mui/material';
import Routes from './routes';
import './App.css'

const App = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false}>
        <Routes />
      </Container>
    </>
  );
};

export default App;
