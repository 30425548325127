import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  Snackbar,
  Box,
  useMediaQuery,
  Alert,
  Paper,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MuiAlert from '@mui/material/Alert';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';
import Defects from './Defects';
import Device from './Device';
import image1 from '../../assets/images/1.jpg';
import image2 from '../../assets/images/2.jpg';
import image3 from '../../assets/images/3.jpg';
import image4 from '../../assets/images/4.jpg';
import image5 from '../../assets/images/5.jpg';
import image6 from '../../assets/images/6.jpg';
import image7 from '../../assets/images/7.jpg';
import image8 from '../../assets/images/8.png';
import image9 from '../../assets/images/9.jpeg';



const Screen = ({
  selectedModel,
  deviceAnswers,
  rowDetails,
  formattedVariant,
  currentValue,
  warrantyStatus,
  FixedPrice,
  answers,
  userLogDetails,
  onFormData
}) => {
  
  const [selectedOptions, setSelectedOptions] = useState({
    screenCondition: null,
    pixelSpots: null,
    visibleLines: null
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [showDefects, setShowDefects] = useState(false);
  const [showScreen, setShowScreen] = useState(true);
  const [showDevice, setShowDevice] = useState(false);
  const[ScrCurrentValue,setScrCurrentValue] = useState(currentValue);

  const categories = [
    {
      id: 'screenCondition',
      title: 'A. Screen physical condition.',
      options: [
        {
          text: 'Screen glass cracked/broken',
          image: image1,
          deduction: rowDetails['Screen cracked/ glass broken']
        },
        {
          text: 'Screen Damaged/Chipped Outside Display Area',
          image: image2,
          deduction: rowDetails['Chipped/cracked outside display area']
        },
        {
          text: '1-2 Minor Scratches On Screen',
          image: image3,
          deduction: rowDetails['1-2 scratches on screen']
        },
        {
          text: 'More Than 2 Major Scratches On Screen',
          image: image4,
          deduction: rowDetails['More than 2 scratches on screen']
        },
        {
          text: 'No Scratches On Screen',
          image: image5,
          deduction: rowDetails['No Sratches']
        }
      ]
    },
    {
      id: 'pixelSpots',
      title: 'B. Dead pixel spots on screen.',
      options: [
        {
          text: 'Minor 1-2 Spots On Display',
          image: image6,
          deduction: rowDetails['1-2 minor spots on screen']
        },
        {
          text: 'Visible Large/Heavy Spots On Display',
          image: image7,
          deduction: rowDetails['Large/ heavy visible spots on screen']
        },
        {
          text: 'No Spots On Display',
          image: image5,
          deduction: rowDetails['No Spot On screen']
        }
      ]
    },
    {
      id: 'visibleLines',
      title: 'C. Visible lines on screen.',
      options: [
        {
          text: 'Lines Are Clearly Visible on Display',
          image: image8,
          deduction: rowDetails['Visible line(s) on display']
        },
        {
          text: 'Visible Faded Corners On Display',
          image: image9,
          deduction: rowDetails['Display faded along edges']
        },
        {
          text: 'No Visible Lines On Screen',
          image: image5,
          deduction: rowDetails['No Line on Screen']
        }
      ]
    }
  ];

  const handleSelection = (categoryId, option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [categoryId]: option
    }));
  };

  const handleContinue = () => {
    const allSelected = Object.values(selectedOptions).every(option => option !== null);
    if (!allSelected) {
      setAlertOpen(true);
    } else {
      if (!FixedPrice) {
        // Collect deductions based on conditions
        let deductions = [];
      
        // Check the conditions and add deductions accordingly
        if (answers.touch === 'no') {
          deductions.push(rowDetails['Device Touch not working']);
        }
      
        if (answers.screenOriginal === 'no') {
          deductions.push(rowDetails["phone's screen original"]);
        }
      
        // Include deductions from selectedOptions if available
        deductions = [
          ...deductions,
          ...Object.values(selectedOptions).map(option => option.deduction)
        ];
       console.log(deductions,"deductions")
        // Calculate max deduction only if deductions array is not empty
        if (deductions.length > 0) {
          const maxDeduction = Math.min(...deductions);
          console.log(maxDeduction,"maxDeduction.....");
          console.log(ScrCurrentValue,"ScrCurrentValue");
          const newValue = ScrCurrentValue + maxDeduction;
          console.log(newValue, "New Value after deduction");
          setScrCurrentValue(newValue);
        } else {
          console.log("No applicable deductions");
        }
      }
      setShowDefects(true); // Show the next component
      setShowScreen(false); // Hide the current component
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleFormData = (data) => {
    console.log("Screen:", data);
    onFormData(data)
    // setFormData(data);
  };

  if (showDevice) {
    return <Device 
    selectedModel={selectedModel} 
    formattedVariant={formattedVariant}
     currentValue={currentValue}
      warrantyStatus={warrantyStatus}
       rowDetails={rowDetails}
        answers={answers} 
        FixedPrice={FixedPrice}
        onFormData={handleFormData}
        />;
  }

  return (
    <Container>
    <Grid container spacing={2} sx={{ mt: 4 }}>
      {showScreen && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
                <Typography
                  variant="h5"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  Select Screen and Touch Glass Condition
                </Typography>
                <Box
      sx={{
        height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
        overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
        paddingBottom: { xs: '80px', md: '0' },
      }}
    >
            {categories.map((category) => (
              <Category
             
                key={category.id}
                categoryId={category.id}
                title={category.title}
                options={category.options}
                selectedOption={selectedOptions[category.id]}
                onSelection={handleSelection}
              />
            ))}
               </Box>
           
           <Box    
           sx={{
        position: { xs: 'fixed', md: 'static' },
        bottom: { xs: 0, md: 'auto' },
        width: { xs: '100%',sm:"100%", md: 'auto' },
        textAlign: 'center',
        backgroundColor: { xs: '#fff', md: 'transparent' },
        // boxShadow: { xs: '0px -2px 10px rgba(0, 0, 0, 0.1)', md: 'none' },
        padding: { xs: 2, md: 0 },
      }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      width: { xs: '100%',sm:'100%',md: 'auto' },
                      marginBottom: { xs: '10px', md: '0' },
                      '&:hover': {
                        backgroundColor: '#45a049',
                      },
                    }}
                    onClick={handleContinue}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Continue
                  </Button>
                </Box>
                <Snackbar
                  open={alertOpen}
                  autoHideDuration={6000}
                  onClose={handleAlertClose}
                >
                  <MuiAlert
                    onClose={handleAlertClose}
                    severity="warning"
                    sx={{
                      backgroundColor: '#f57c00',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Please select an option for each condition!
                  </MuiAlert>
                </Snackbar>
              </Paper>
          </Grid>
          {!isMobile && (
          <Grid item xs={12} md={5}>
            <DeviceEvaluation 
            selectedModel={selectedModel}
             deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
             formattedVariant={formattedVariant}
              answers={answers}/>
          </Grid>
          )}
        </Grid>
      )}
       </Grid>
       
      {showDefects && (
        
          <Defects 
        selectedModel={selectedModel}
          formattedVariant={formattedVariant} 
          currentValue={ScrCurrentValue} 
          warrantyStatus={warrantyStatus}
          rowDetails={rowDetails}
            FixedPrice={FixedPrice}
            answers={answers}
              ScreenselectedOptions={selectedOptions}
              deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
              userLogDetails={userLogDetails}
              onFormData={handleFormData}
                 />
         
      )}
      
      </Container>
   
  );
};

const Category = ({ categoryId, title, options, selectedOption, onSelection }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6" className='conditionssubheading'>{title}</Typography>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Item
            key={option.text}
            categoryId={categoryId}
            option={option}
            isSelected={selectedOption?.text === option.text}
            onClick={() => onSelection(categoryId, option)}
          />
        ))}
      </Grid>
    </div>
  );
};

const Item = ({ option, isSelected, onClick }) => {
  return (
    <Grid item xs={6} sm={6} md={4}>
    <Box
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          border: isSelected ? '2px solid green' : '1px solid #ddd',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
          height: '300px', // Set a fixed height for uniformity
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={option.image}
          alt={option.text}
          style={{
            width: '100%',
            height: '180px', // Adjust height to balance the overall layout
            objectFit: 'contain',
            borderBottom: '1px solid #ddd',
          }}
        />
  <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: isSelected ? 'green' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >          {option.text}
        </Typography>
      </Box>
    </Grid>
  );
};

export default Screen;
