import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Paper
  , IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from 'react-router-dom';

import EmailIcon from '@mui/icons-material/Email';
import cos from '../../assets/images/logo_cos.jpeg'; // Ensure you have the correct path to the image
import handshake from '../../assets/images/handshake.jpeg'; // Ensure you have the correct path to the image
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: "3%",
    // borderRadius: theme.spacing(1),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',    // maxWidth: 900,
    margin: '3%',
  },
  logoContainer: {
    // textAlign: 'center',
    marginBottom: "3px",

  },
  image: {
    width: '15%',
    height: 'auto',
    borderRadius:"1px solid #ccc",
    marginRight:"auto"
  },
  image1: {
    width: '50%',
    height: 'auto',
    borderRadius:"1px solid #ccc",
    marginRight:"auto"
  },
  text: {
    color: '#06A89D',
  },
  button: {
    backgroundColor: '#06A89D !important',
    color: '#000 !important',
    marginTop: "2px",
    '&:hover': {
      backgroundColor: '#045D5D',
    },
    borderRadius:"25px !important"
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "2%",
    '& > *': {
      marginRight: "1%",
    },
  },
  icon: {
    color: '#06A89D',
  },
}));

const PartnerWithUs = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <Container className={classes.root}>
        <IconButton onClick={handleBackClick} className={classes.backButton}>
         <Button startIcon={<ArrowBackIcon/>}>Go back</Button> 
        </IconButton>
      <Box className={classes.logoContainer}>
        <img src={cos} alt="Logo" className={classes.image} /> {/* Replace with your logo */}
      </Box>
      <Grid container spacing={3} style={{display:"flex",alignItems:"center"}}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom className={classes.text}>
            Grow Your Business With Us
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.text}>
            Why to Become a Cashonspot Business Partner?
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">Get leads from the pincode you choose</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">Grow your business & Accelerate profit</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">We provide full support</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">Pay less, earn more and increase your inventory</Typography>
          </Box>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScyGYbfbqQxEaHXfMYivRYhw-pU4SjcV5Ghnp9VJqUaBfDiEQ/viewform?vc=0&c=0&w=1&flr=0', '_blank')}
          >
            Become a partner
          </Button>
          <Box className={classes.contact} mt={2}>
            <PhoneIcon className={classes.icon} />
            <Typography variant="body1">+91-8296624506</Typography>
          </Box>
          <Box className={classes.contact} mt={2}>
            <LanguageIcon className={classes.icon} />
            <Typography variant="body1">www.cashonspot.in</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.imageContainer}>
            <img src={handshake} alt="Handshake" className={classes.image1} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PartnerWithUs;
