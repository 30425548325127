import React, { useState } from 'react';
import { Container, Grid, Typography, Button,useMediaQuery,  Box, Paper} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeviceEvaluation from '../commonForms/DeviceEvaluation'; // Update the import path as necessary
import Accessories from './Accessories'; // Update the import path as necessary
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Defects from './Defects';
import image1 from '../../assets/images/F1.jpg';
import image2 from '../../assets/images/F2.jpg';
import image3 from '../../assets/images/F3.jpg';
import image4 from '../../assets/images/F4.jpg';
import image5 from '../../assets/images/F5.jpg';
import image6 from '../../assets/images/F6.jpg';
import image7 from '../../assets/images/F7.jpg';
import image8 from '../../assets/images/F8.jpg';
import image9 from '../../assets/images/F9.jpg';
import image10 from '../../assets/images/F10.jpg';
import image11 from '../../assets/images/F11.jpg';
import image12 from '../../assets/images/F12.jpg';
import image13 from '../../assets/images/F13.jpg';
import image14 from '../../assets/images/F14.jpg';
import image15 from '../../assets/images/F15.jpg';
import image16 from '../../assets/images/F16.jpg';



const Functional = ({ selectedModel,  deviceAnswers,rowDetails,formattedVariant,currentValue,warrantyStatus,goBack,answers,FixedPrice,showWarrantyPage,ScreenselectedOptions,DefectsselectedOptions,userLogDetails,onFormData}) => {
  console.log(onFormData,"functional....")
  const [selectedOptions, setSelectedOptions] = useState({"functionalProblems":[]});
  const [showAccessories, setShowAccessories] = useState(false);
  const [showFunctional, setShowFunctional] = useState(true);
  const [showDefects, setShowDefects] = useState(false);
  const[FuncCurrVal,setFuncCurrVal] = useState(currentValue)

  const handleSelection = (categoryId, option) => {
    console.log(categoryId,option,"category","id")
    setSelectedOptions((prevSelectedOptions) => {
      const currentSelected = prevSelectedOptions[categoryId] || [];
      const isSelected = currentSelected.some((selectedOption) => selectedOption.label === option.label);

      if (isSelected) {
        const updatedSelection = currentSelected.filter((selectedOption) => selectedOption.label !== option.label);
        return {
          ...prevSelectedOptions,
          [categoryId]: updatedSelection,
        };
      } else {
        return {
          ...prevSelectedOptions,
          [categoryId]: currentSelected.concat(option),
        };
      }
    });
  };

  const categories = [
    {
      id: 'functionalProblems',
      title: 'Physical or Functional Problems',
      subtitle: 'Choose exact condition to get accurate value',
      options: [
        { label: 'Front Camera not working', imageUrl: image1,deduction: rowDetails['Front Camera'] },
        { label: 'Back Camera not working', imageUrl: image2,deduction: rowDetails['Back Camera'] },
        { label: 'Camera Glass Broken', imageUrl: image3,deduction: rowDetails['Camera Glass'] },
        { label: 'Finger Touch not working', imageUrl: image4,deduction: rowDetails['Finger Touch'] },
        { label: 'Face Sensor not working', imageUrl: image5 ,deduction: rowDetails['Face Sensor']},
        { label: 'WiFi not working', imageUrl: image6 ,deduction: rowDetails['Wifi']},
        { label: 'Bluetooth not working', imageUrl: image7,deduction: rowDetails['Bluetooth'] },
        { label: 'Volume Button not working', imageUrl: image8,deduction: rowDetails['Volume Button '] },
        { label: 'Power Button not working', imageUrl: image9,deduction: rowDetails['Power buttons'] },
        { label: 'Silent Button not working', imageUrl: image10 ,deduction: rowDetails['Silent Button']},
        { label: 'Vibrator is not working', imageUrl: image11 ,deduction: rowDetails['Vibrator'] },
        { label: 'Battery in Service (Health is less than 80%)', imageUrl: image12  ,deduction: rowDetails['Battery']},
        { label: 'Speaker Faulty', imageUrl: image13 ,deduction: rowDetails['speaker'] },
        { label: 'Charging Port not working', imageUrl: image14 ,deduction: rowDetails['Charge port'] },
        { label: 'Microphone not working', imageUrl: image15 ,deduction: rowDetails['Microphone'] },
        { label: 'Audio Receiver not working', imageUrl: image16 ,deduction: rowDetails['Audio Receiver']},
        { label: 'proximity sensor', imageUrl: image16 ,deduction: rowDetails['proximity sensor']},
      ],
    },
  ];

  const handleContinue = () => {
    const allCategoriesSelected = categories.every((category) => selectedOptions[category.id]);
    if (!allCategoriesSelected) {
      alert('Please select options for all categories.');
      return;
    }
    else {
      if (!FixedPrice) {
        const deductions = selectedOptions['functionalProblems'].map(option => option.deduction);

        const totalDeduction = deductions.reduce((acc, deduction) => acc + deduction, 0);
        console.log(totalDeduction,"totalDeduction")
       const newValue = FuncCurrVal + totalDeduction;
        setFuncCurrVal(newValue);
        console.log(newValue, "New Value after deduction");
      }
    setShowAccessories(true);

    }
    
    setShowFunctional(false);
  };

  const handleBack = () => {
    setShowFunctional(false);
    setShowDefects(true);
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleFormData = (data) => {
    console.log(data,"Fundddd")
    // console.log("Received form data func:", data);
    onFormData(data)
    
  };

  return (
    <>
      {showFunctional && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
          <Box
        sx={{
          height: { xs: 'calc(100vh - 120px)', md: 'auto' },
          overflowY: { xs: 'scroll', md: 'visible' },
          paddingBottom: { xs: '80px', md: '0' },
        }}
      >
           {categories.map((category) => (
             
              <React.Fragment key={category.id}>
                
                 <Typography
                  variant="h5"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >                  
                {category.title}
                </Typography>
                <Typography
                  variant="h6"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >                  
                {category.subtitle}
                </Typography>
                <Grid container spacing={2}>
                  {category.options.map((option, index) => (
                    <Grid item xs={6} md={3} key={index}>
      
                      <Box
                        // fullWidth
                        // variant={selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? '' : 'outlined'}
                        onClick={() => handleSelection(category.id, option)}
                        sx={{
                          border: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label)
                            ? '2px solid red' 
                            : '1px solid #ddd',
                          display: 'flex',
                          cursor: 'pointer',

                          flexDirection: 'column',
                          boxShadow: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',

                          alignItems: 'center',
                          textAlign: 'center',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                          },
                          overflow: 'hidden',

                          p: 2,
                          height: '300px', // Set a fixed height for uniformity

                        
                        }}
                      >
                        <img 
                        src={option.imageUrl} 
                        alt={option.label}
                        style={{
                          width: '100%',
                          height: '180px', // Adjust height to balance the overall layout
                          objectFit: 'contain',
                          borderBottom: '1px solid #ddd',
                        }}
                           />

<Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? 'red' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >   {option.label}
        
        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
            </Box>

<Box
        sx={{
          position: { xs: 'fixed', md: 'static' },
          bottom: { xs: 0, md: 'auto' },
          width: { xs: '100%',sm:'100%', md: 'auto' },
          textAlign: 'center',
          backgroundColor: { xs: '#fff', md: 'transparent' },
          // boxShadow: { xs: '0px -2px 10px rgba(0, 0, 0, 0.1)', md: 'none' },
          padding: { xs: 2, md: 0 },
        }}
      >
            
            <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      width: { xs: '100%',sm:'100%', md: 'auto' },
                      marginBottom: { xs: '10px', md: '0' },
                      '&:hover': {
                        backgroundColor: '#45a049',
                      },
                    }}
                    onClick={handleContinue}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Continue
                  </Button>
                  </Box>
                  </Paper>
                       </Grid>
          {!isMobile && (  
          <Grid item xs={12} md={5}>
            <DeviceEvaluation 
            selectedModel={selectedModel}
             deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
               formattedVariant={formattedVariant} 
               answers={answers} />
          </Grid>
          )}
        </Grid>
      )}

      {showAccessories && (
        
          <Accessories  
          selectedModel={selectedModel} 
          formattedVariant={formattedVariant}
           currentValue={FuncCurrVal}
            warrantyStatus={warrantyStatus}
             rowDetails={rowDetails} 
             answers={answers} 
             FixedPrice={FixedPrice}
              showWarrantyPage={showWarrantyPage}
               deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}
                ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions}
                 functionalSelectedOptions={selectedOptions}
                 userLogDetails={userLogDetails}
                 onFormData={handleFormData}
                 />
        
      )}
    </>
  );
};

export default Functional;
