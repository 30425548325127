import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Snackbar, Box,useMediaQuery, Paper } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Functional from './Functional';
import MuiAlert from '@mui/material/Alert';

import Screen from './Screen';
import image1 from '../../assets/images/10.jpg';
import image2 from '../../assets/images/12.jpg';
import image3 from '../../assets/images/14.jpg';
import image4 from '../../assets/images/15.jpg';
import image5 from '../../assets/images/17.jpg';
import image6 from '../../assets/images/4.jpg';
import Image13 from '../../assets/images/Image13.jpeg';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';




const Defects = ({ selectedModel,  deviceAnswers,rowDetails,formattedVariant,currentValue,warrantyStatus,goBack,answers,FixedPrice,ScreenselectedOptions,userLogDetails,onFormData}) => {
  console.log(onFormData,"defects....")
  const [selectedOptions, setSelectedOptions] = useState({
    overallCondition : null,
    bodyDefects: null,

  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [showFunctional, setShowFunctional] = useState(false);
  const [showDefects, setShowDefects] = useState(true);
  const [showScreen, setShowScreen] = useState(false);
  const[DefCurrValue,setDefCurrValue] = useState(currentValue);
  const[showWarrantyPage,setshowWarrantyPage] = useState(true);


  // Warranty Deduction 






  const handleSelection = (categoryId, option) => {
    console.log(selectedOptions,"current ")

    setSelectedOptions((prev) => ({
      ...prev,
      [categoryId]: prev[categoryId] === option ? null : option,
    }));
  };

  const categories = [
    {
      id: 'overallCondition',
      title: "Phone's Overall Condition",
      options: [
        { text: 'Like New - Zero Scratches, Zero Dents, Flawless', image: image1,  deduction: rowDetails['Like New - Zero Scratches, Zero Dents, Flawless'] },
        { text: 'Good-1-2 scaraches,minor dents,no crack', image: image2,deduction: rowDetails['Like New - Zero Scratches, Zero Dents, Flawless'] },
        { text: 'More than 2 Scratches and Major dents', image: image6,deduction: rowDetails['More than 2 Scratches,Major dents'] },
        { text: 'Average:-major scraches, major dents,panel crackes, loose screen gap', image: image3 ,deduction: rowDetails[' Average:-major scraches, major dents,panel crackes, loose screen gap']},
        { text: 'Below Average:-Heavy scraches,Heavy dents and phone bent', image: image4 ,deduction: rowDetails['Below Average:-Heavy scraches,Heavy dents and phone bent']},
      ],
    },
    {
      id: 'bodyDefects',
      title: 'Device Body Defects',
      options: [
        { text: 'Phone Bent With Panel Crack', image: image5,deduction: rowDetails['Phone Bent With Panel Crack'] },
        { text: 'Only Panel Broken', image: image5 ,deduction: rowDetails['Only Panel Broken']},
        { text: 'Loose Screen Gap', image: image4 ,deduction: rowDetails['Loose Screen Gap']},
        { text: 'Phone Bent', image: Image13 ,deduction: rowDetails['Phone Bent ']},
        { text: 'No Body Defects', image: image1,deduction: rowDetails['No body defect']  },
 
      ],
    },
  ];

  const handleContinue = () => {
    const allCategoriesSelected = categories.every((category) => selectedOptions[category.id]);
    if (!allCategoriesSelected) {
      setAlertOpen(true);
      return;
    }
    else {
      let newValue = DefCurrValue;
     if (!FixedPrice) {
        const deductions = Object.values(selectedOptions).map(option => option.deduction);
        console.log(deductions,"deductions")
        const maxDeduction = Math.min(...deductions);
        console.log(maxDeduction, "maxDed");
  
        const warrantyConditions = [
          "touch",
          "screenOriginal",
          "Screen cracked/ glass broken",
          "Average:-major scraches, major dents,panel crackes, loose screen gap",
          "Below Average:-Heavy scraches,Heavy dents and phone bent",
          "Phone Bent",
          "Phone Bent With Panel Crack",
          "Only Panel Broken"
        ];
  
        const shouldDeductWarranty = answers.touch === "no" || answers.screenOriginal === "no" || warrantyConditions.some(condition => Object.values(selectedOptions).map(option => option.text).includes(condition));
        // console.log(shouldDeductWarranty,"shouldDeductWarranty")
  
        if (shouldDeductWarranty) {
          console.log(rowDetails["> 11 months"],"warranty deduction....")
          newValue += rowDetails["> 11 months"];
          setshowWarrantyPage(false);

        }
  
        newValue += maxDeduction;
        setDefCurrValue(newValue);
        console.log(newValue, "New Value after deduction");
      }
  
      setShowFunctional(true);
      setShowDefects(false);
    }
    
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  

  const handleFormData = (data) => {
    console.log("Defects:", data);
    onFormData(data)
    // setFormData(data);
  };
   

  return (
    <>
      {showDefects && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}>
          <Typography variant="h5" sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>Select Screen and Touch Glass Condition</Typography>
          <Box
      sx={{
        height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
        overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
        paddingBottom: { xs: '80px', md: '0' },
      }}
    >
          {categories.map((category) => (
              <Category
                key={category.id}
                categoryId={category.id}
                title={category.title}
                options={category.options}
                selectedOption={selectedOptions[category.id]}
                onSelection={handleSelection}
              />
            ))}
             </Box>
                 
           <Box    
           sx={{
        position: { xs: 'fixed', md: 'static' },
        bottom: { xs: 0, md: 'auto' },
        width: { xs: '100%',sm:"100%", md: 'auto' },
        textAlign: 'center',
        backgroundColor: { xs: '#fff', md: 'transparent' },
        // boxShadow: { xs: '0px -2px 10px rgba(0, 0, 0, 0.1)', md: 'none' },
        padding: { xs: 2, md: 0 },
      }}
      >
          
          <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#4caf50',
                      color: 'white',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      width: { xs: '100%',sm:'100%',md: 'auto' },
                      marginBottom: { xs: '10px', md: '0' },
                      '&:hover': {
                        backgroundColor: '#45a049',
                      },
                    }}
                    onClick={handleContinue}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Continue
                  </Button>
            </Box>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
              <MuiAlert elevation={6} variant="filled" onClose={handleAlertClose} severity="error">
                Please select options for all categories.
              </MuiAlert>
            </Snackbar>
            </Paper>
          </Grid>
          {!isMobile && (          
            <Grid item xs={12} md={5}>
            <DeviceEvaluation selectedModel={selectedModel} deviceAnswers={{ ...deviceAnswers, ...selectedOptions }}  formattedVariant={formattedVariant} answers={answers}/>
          </Grid>
          )} 
        </Grid>
      )}
      {showFunctional && (
         
          <Functional 
          selectedModel={selectedModel} 
          formattedVariant={formattedVariant}
           currentValue={DefCurrValue}
            warrantyStatus={warrantyStatus}
             rowDetails={rowDetails}
              answers={answers}
               FixedPrice={FixedPrice}
                showWarrantyPage={showWarrantyPage}
                 deviceAnswers={{ ...deviceAnswers, ...selectedOptions }} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={selectedOptions} 
                 userLogDetails={userLogDetails}
                 onFormData={handleFormData}

                 
                 />
        
      )}
    </>
  );
};

const Category = ({ categoryId, title, options, selectedOption, onSelection }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444', mb: 2 }}>{title}</Typography>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Item
            key={option.text}
            categoryId={categoryId}
            option={option}
            isSelected={selectedOption?.text === option.text}
            onClick={() => onSelection(categoryId, option)}
          />
        ))}
      </Grid>
    </div>
  );
};

const Item = ({ option, isSelected, onClick }) => {
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Box
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          border: isSelected ? '2px solid green' : '1px solid #ddd',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
          height: '300px', // Set a fixed height for uniformity
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={option.image}
          alt={option.text}
          style={{
            width: '100%',
            height: '180px', // Adjust height to balance the overall layout
            objectFit: 'contain',
            borderBottom: '1px solid #ddd',
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: isSelected ? 'green' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {option.text}
        </Typography>
      </Box>
    </Grid>
  );
};


export default Defects;
