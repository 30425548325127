import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  CircularProgress,

  Alert,
  InputAdornment} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginImg from '../../assets/images/Login.jpeg';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Email from '@mui/icons-material/Email';
import { Link, useNavigate } from 'react-router-dom';
import { config } from '../../config';

 
import axios from 'axios';


const CusotmerLoginInitial = ( ) => {
  const [currentPage, setCurrentPage] = useState('phoneNumber');
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [isResendAllowed, setIsResendAllowed] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
   
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
   const [newUser, setNewUser] = useState(false);
   const [loading, setLoading] = useState(false);


  useEffect(() => {
    let timer;
    if (!isResendAllowed) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsResendAllowed(true);
            return 60;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isResendAllowed]);

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(`${config.Api}send-otp`, { phoneNumber });
      if (response.status === 200) {
        setIsOtpSent(true);
        setIsResendAllowed(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert('Failed to send OTP');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`${config.Api}verify-otp`, { phoneNumber, otp });
      if (response.status === 200) {
        setCurrentPage('sellNowPage');
        setOtpError('');
        navigate('/');
      } else {
        setOtpError(response.data.message);
      }
    } catch (error) {
      setOtpError('Failed to verify OTP');
    }
  };


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handlePhoneNumberSubmit = async () => {
    try {
      const response = await axios.post(`${config.Api}check-phone-number`, { phoneNumber });
      if (response.data.exists) {
        // setCurrentPage('details');
        // setCurrentPage('otp');
        // handleSendOtp();
        const existingUser = response.data.user; // Assuming you send back the user data if they exist
      navigate('/', { state: { name: existingUser.name,email:existingUser.email,phoneNumber:existingUser.phoneNumber}});

      } else {
        setNewUser(true);
        setCurrentPage('details');
        setFormData({ ...formData, phoneNumber });
      }
    } catch (error) {
      alert('Error checking phone number');
    }
  };



  const handleContinue = async () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (Object.keys(newErrors).length === 0) {
      navigate('/', { state: { name: formData.name,email:formData.email,phoneNumber:formData.phoneNumber}});

    } else {
      setErrors(newErrors);
      setMessage('Please correct the errors in the form.');
      setMessageType('error');
    }
  };
 
  

  return (
    <>
  <Grid container spacing={2} sx={{ mt: 4 }} style={{ display: "flex", justifyContent: "center" }}>
      <Paper elevation={3} style={{ display: 'flex', padding: '20px', maxWidth: "1000px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ backgroundColor: '#36225b', padding: '20px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img
                src={LoginImg ? LoginImg : "https://via.placeholder.com/300x300?text=Login+Image"}
                alt="Login Illustration"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {currentPage === 'phoneNumber' && (
              <>
                <TextField
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
                  onClick={handlePhoneNumberSubmit}
                  endIcon={<ArrowForwardIcon />}
                >
                  Continue
                </Button>
              </>
            )}
            {currentPage === 'otp' && (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: '#D8F2E8', color: '#06A89D', marginBottom: '20px' }}
                  onClick={handleSendOtp}
                  disabled={!isResendAllowed && isOtpSent}
                >
                  {isResendAllowed ? 'Send OTP' : `Resend OTP in ${countdown}s`}
                </Button>
                <Typography variant="body2" gutterBottom>
                  Enter OTP
                </Typography>
                <Grid container spacing={1}>
                  {[...Array(6)].map((_, index) => (
                    <Grid item xs={2} key={index}>
                      <TextField
                        variant="outlined"
                        value={otp[index] || ''}
                        onChange={(e) => {
                          const newOtp = otp.split('');
                          newOtp[index] = e.target.value;
                          setOtp(newOtp.join(''));
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                {otpError && <Alert severity="error">{otpError}</Alert>}
                <Button
                  variant="contained"
                  sx={{ mt: 4, backgroundColor: 'lightgreen', color: 'white' }}
                  onClick={handleVerifyOtp}
                  endIcon={<ArrowForwardIcon />}
                >
                  Verify OTP
                </Button>
                <FormControlLabel
                  control={<Checkbox name="checkedA" />}
                  label={
                    <Typography variant="body2">
                      I agree to the <Link to="/terms" style={{ textDecoration: "underline" }} onClick={() => navigate('/terms')}>Terms and Conditions</Link> & <a href="#privacy">Privacy Policy</a>
                    </Typography>
                  }
                />
              </>
            )}
            {currentPage === 'details' && (
              <>
                  <Grid container spacing={2} sx={{ mt: 3, px: 2 }}>
  <Grid item xs={12} sm={6}>
    <TextField
      fullWidth
      name="name"
      label="Full Name"
      type="text"
      value={formData.name}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        ),
      }}
      error={!!errors.name}
      helperText={errors.name}
      sx={{ 
        mt: 2,
        '& .MuiInputBase-root': { 
          backgroundColor: 'white' 
        },
      }}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      fullWidth
      name="email"
      label="Email Address"
      type="email"
      value={formData.email}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        ),
      }}
      error={!!errors.email}
      helperText={errors.email}
      sx={{ 
        mt: 2,
        '& .MuiInputBase-root': { 
          backgroundColor: 'white' 
        },
      }}
    />
  </Grid>

  <Grid item xs={12}>
    <Button
      variant="contained"
      fullWidth
      sx={{
        mt: 4,
        py: 1.5,
        backgroundColor: 'lightgreen',
        color: 'white',
        fontSize: '1rem',
        '&:hover': { backgroundColor: 'green' },
        borderRadius: '8px',
        transition: 'all 0.3s ease-in-out',
      }}
      onClick={handleContinue}
      endIcon={loading ? <CircularProgress size={24} /> : <ArrowForwardIcon />}
      disabled={loading}
    >
      {loading ? 'Submitting...' : 'Continue'}
    </Button>
  </Grid>
</Grid>

              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
      
       </>
  );
};

export default CusotmerLoginInitial;
