import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, Button, Box, Avatar, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { Email, Edit, CheckCircle, Cancel, PendingActions, Person } from '@mui/icons-material';
import Tick from '../../assets/images/Tick.jpeg'; // Replace with your image path
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';

const ProfileContainer = styled(Container)({
  marginTop: '20px',
});

const ProfileCard = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

const CancelledOrderCard = styled(Paper)({
  padding: '20px',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

 

const VerificationPendingBox = styled(Box)({
  backgroundColor: '#fff3cd',
  padding: '10px',
  marginTop: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});


const getStatusIcon = (status) => {
  switch (status) {
    case 'Completed':
      return <CheckCircle />;
    case 'Failed':
      return <Cancel />;
    case 'Pending':
    default:
      return <PendingActions />;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Failed':
      return 'red';
    case 'Pending':
    default:
      return 'orange';
  }
};





const ProfilePage = () => {
const navigate = useNavigate();
const location = useLocation();
const {name,email,PhoneNumber} = location.state || {}
const [userDetails, setUserDetails] = useState([]);
const [finalAmounts, setFinalAmounts] = useState({});
const [loading, setLoading] = useState(false);
const [loadingStates, setLoadingStates] = useState({});




  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`${config.Api}userpickupdetails`, {email });
        
        console.log(response.data.records,"response.data.records");
        // console.log(response1.data,"Proofs")
        // setUserDetails1(response1.data);
        setUserDetails(response.data.records); // Assuming response.data is an array
      } catch (error) {
        console.error('Error fetching user details:', error);
        alert('Error fetching user details');
      }
    };

    if (email) {
      fetchUserDetails();
    }
  }, [email]);

  const handleShowFinalAmount = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));

    try {
      const response = await axios.post(`${config.Api}get-proof-details/${id}`);
      console.log(response.data[0].finalPrice,"response.data.finalPrice")
      setFinalAmounts((prev) => ({ ...prev, [id]: response.data[0].finalPrice }));
    } catch (error) {
      console.error('Error fetching final amount:', error);
      alert('No Final amount Found for this');
    } finally {
      setLoadingStates((prev) => ({ ...prev, [id]: false }));
    }
};

  return (
    <ProfileContainer maxWidth="lg">
      <IconButton onClick={() => navigate("/", { state: { name: userDetails?.[0]?.Name, email: userDetails?.[0]?.Email } })}>
        <Button startIcon={<ArrowBackIcon />}>Go back</Button>
      </IconButton>
      {
       userDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {/* Enhanced ProfileCard with Avatar */}
            <ProfileCard elevation={3}>
              <Avatar sx={{ width: 80, height: 80, margin: 'auto' }}>
                <Person fontSize="large" />
              </Avatar>
              <Typography variant="h6" sx={{ mt: 2 }}>{userDetails?.[0]?.Name}</Typography>
              <Typography variant="body1">{userDetails?.[0]?.PhoneNumber}</Typography>
              <Typography variant="body2">{userDetails?.[0]?.Email}</Typography>
             
            </ProfileCard>
          </Grid>
          <Grid item xs={12} md={8}>
            {userDetails.map((user, id) => (
              <>
              <CancelledOrderCard key={id} elevation={3}>
                 <Box display="flex" alignItems="center">
                  <Avatar
                    sx={{ backgroundColor: getStatusColor(user.Status), marginRight: '10px' }}
                  >
                    {getStatusIcon(user.Status)}
                  </Avatar>
                  <Box>
                    <Typography variant="body1" fontWeight={"bold"} color={user.Status === "Failed" ? "error" : user.Status === null ? "orange" : user.Status === "Completed" ? "green" : ""}>Pickup {user.Status || "pending"}</Typography>
                    <Typography variant="body2">{user["Pickup Date"]}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
  {/* Model Image */}
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: '1px solid #ddd',
                }}
              >
                <img
                  src={user?.ImageURL|| 'https://via.placeholder.com/80'}
                  alt={`${user["Brand Name"]} ${user.Model}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
  
  {/* Text Content */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                      {user["Brand Name"]} {user.Model}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                      {user.formattedVariant}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                      Order ID: {user.ID}
                    </Typography>
                    {/* <Link href="#" sx={{ color: 'primary.main', fontWeight: 'bold', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                      View order details
                    </Link> */}
                    {/* <p>Note: Final Sell Amount Will be available in Your Mail After the Conversation from our agent</p> */}
                  </Box>
             </Box>
             <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="p" sx={{ fontWeight: 'bold', color: 'red' }}>
                      Sell Amount ₹{user.QuotedPrice}
                    </Typography>
                 
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                     <Button variant="outlined" size="small" onClick={() => handleShowFinalAmount(user.ID)}>
                      Show Final Amount
                    </Button>
                    {loadingStates[user.ID] && <CircularProgress size={20} />}
                      {finalAmounts[user.ID] && (
                       <Typography variant="p" sx={{ fontWeight: 'bold', color: 'red' }}>
                          Final Amount   ₹{finalAmounts[user.ID]}
                        </Typography>
                      )}
                                  </Box>
                  </CancelledOrderCard>
              </>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Typography>No Records Found</Typography>
      )}
    </ProfileContainer>
  
  );
};

export default ProfilePage;
