import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography, RadioGroup, FormControlLabel, Radio, Button, CircularProgress, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Device from './Device';
import {config} from "../../config"

const VariantSelectionCard = ({ selectedModel, goBack,city,userLogDetails,onFormData}) => {
  console.log(onFormData,"Variant....")
  const [selectedVariant, setSelectedVariant] = useState('');
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [showVariant, setShowVariant] = useState(true);
  const [maxValue, setMaxValue] = useState(null);
  const [formattedVariant, setFormattedVariant] = useState('');
  const [rowDetails, setRowDetails] = useState(null);




  useEffect(() => {
    // Example API endpoint
    const fetchVariants = async () => {
      setLoading(true);
      try {
        // Construct payload with brand and model (and optionally series)
        const payload = {
          // productCode : selectedModel["Product Code"],
          brand: selectedModel["Brand name"],
          model: selectedModel.Model,
          series: selectedModel.Series, // Optional, handle accordingly
          city : city
        };
        
        // Replace with your actual API endpoint and method
        const response = await fetch(`${config.Api}variants`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch variants');
        }

        const data = await response.json();
        // Assuming data structure contains variants and max values
        setVariants(data.variants);
      } catch (error) {
        console.error('Error fetching variants:', error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedModel) {
      fetchVariants();
    }
  }, [selectedModel]);

  const handleGetExactValueClick = async () => {
    const variant = variants.find(v => v.variant === selectedVariant);
    if (variant) {
      setMaxValue(variant.maxValue);

      try {
        const payload = {
          productCode : selectedModel["Product Code"],
          brand: selectedModel["Brand name"],
          variant: selectedVariant,
          series: selectedModel.Series,
          model: selectedModel.Model,
          maxValue: variant.maxValue
        };

        const response = await fetch(`${config.Api}getEntireRowDetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          if (response.status === 404) {
            alert('No Records Found for this Model');
             return false;
          } else {
            throw new Error('Failed to fetch row details');
          }
        } else {
          const data = await response.json();
          // alert("Records Fetched Successfully")
          setRowDetails(data);
          setShowEvaluation(true);
          setShowVariant(false);
        }
      } catch (error) {
        console.error('Error fetching row details:', error);
        
      }
    }
   
  
  };

  const handleVariantChange = (event) => {
    const selected = event.target.value;
    setSelectedVariant(selected);
    const variant = variants.find(v => v.variant === selected);
    if (variant) {
      setMaxValue(variant.maxValue);
      setFormattedVariant(formatVariant(selected));

    } else {
      setMaxValue(null); // Reset maxValue if no variant is found
      setFormattedVariant('');
    }
  };

  const handleFormData = (data) => {
    console.log("Variiii:", data);
    onFormData(data);
    // onFormData(data)
    // setFormData(data);
  };


  if (showEvaluation) {
    return <Device  selectedModel={selectedModel}  formattedVariant={formattedVariant} goBack={() => setShowEvaluation(false)} rowDetails={rowDetails} city={city} userLogDetails={userLogDetails} onFormData={handleFormData}/>;
  }

  const formatVariant = (variant) => {
    const parts = variant.split('/');
    if (parts.length === 2) {
      return `${parts[0]}GB/${parts[1]}GB`;
    }
    return variant;
  };

   

  return (
    <div>
      {showVariant && (
        <Card sx={{ boxShadow: 3, borderRadius: 2,mt:2 }}>
          <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
          <Grid container spacing={3}>
          {/* Left Side: Mobile Image */}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center' }}>
          <Box sx={{ width: 120, height: 120, backgroundColor: '#f8f8f8', borderRadius: '10px', overflow: 'hidden' }}>
                  <img src={selectedModel[" Image URL"]} alt="Mobile" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </Box>              
                </Grid>

              {/* Right Side: Details and Variant Selection */}
              <Grid item xs={12} sm={8}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                  {`${selectedModel["Brand name"]} ${selectedModel["Model"]}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{
                    mb: 1,
                    fontWeight: 'bold',
                    '& span': {
                      color: 'red',
                    },
                  }}
                >
  {selectedVariant && `(${formatVariant(selectedVariant)})`}{' '}
 
</Typography>
<Typography   variant="subtitle1"
                  sx={{
                    mb: 1,
                    fontWeight: 'bold',
                     color:'red',
                     fontSize:"large"
                  }}>
 Price: ₹{maxValue || 'N/A'} 
</Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1rem' } }}>Choose Variant:</Typography>
                <RadioGroup value={selectedVariant} onChange={handleVariantChange} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    variants.map((variant, index) => (
                      <FormControlLabel
                        key={index}
                        value={variant.variant}
                        control={<Radio />}
                        label={formatVariant(variant.variant)}
                        sx={{
                          backgroundColor: selectedVariant === variant.variant ? '#e8f5e9' : '#fafafa',
                          borderRadius: 2,
                          px: 2,
                          py: 1,
                          m: 1,
                          boxShadow: selectedVariant === variant.variant ? '0 3px 5px rgba(0,0,0,0.2)' : 'none',
                          '&:hover': {
                            backgroundColor: selectedVariant === variant.variant ? '#e8f5e9' : '#f5f5f5',
                          },
                        }}
                      />
                    ))
                  )}
                </RadioGroup>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    backgroundColor: selectedVariant ? '#f44336' : '#f8d7da',
                    color: 'white',
                    padding: '8px 16px',
                    fontWeight: 'bold',
                    boxShadow: selectedVariant ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    '&:hover': {
                      backgroundColor: selectedVariant ? '#e53935' : '#f8d7da',
                    },
                  }}
                  endIcon={<ArrowForwardIcon />}
                  disabled={!selectedVariant}
                  onClick={handleGetExactValueClick}
                >
                  Get Exact Value
                </Button>

                {/* Section below "Get Exact Value" button */}
                <Box sx={{ 
                  backgroundColor: '#424242', 
                  color: 'white', 
                  padding: '15px 20px', 
                  borderRadius: 2,
                  mt: 2 
                }}>
                  <Typography variant="body2">
                    The price stated above depends on the condition of the device and is not final.<br/> 
                    The final price will be quoted at the end of the evaluation.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default VariantSelectionCard;
