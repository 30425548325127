import React, { useState,useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  CardMedia,
  CardActions,
  CardActionArea} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Pickup from './Pickup';
import LoginSignupPage from './CustomerLogin';
import safe from "../../assets/images/cos1.jpeg";
import cos2 from "../../assets/images/cos2.jpeg";
import payment_icon from "../../assets/images/payment_icon.jpeg";
import Device from '../screens/Device';
 
const SellNow = ({selectedModel,  deviceAnswers,formattedVariant,warrantyStatus,goBack,currentValue,rowDetails,answers,FixedPrice,ScreenselectedOptions,DefectsselectedOptions,functionalSelectedOptions,accSelectedOptions,userLogDetails,onFormData}) => {
  console.log(userLogDetails,"SELLL....");
  console.log(deviceAnswers,"deviceans");
  console.log(answers,"answers...")
  const [currentPage, setCurrentPage] = useState('sellNow');
  const[DefaultPrice,setPrice] = useState(0);
  const handleContinue = () => {
    setCurrentPage('pickupPage');
  };
 const handleBack = () => {
    setCurrentPage('loginSignup');
  };

  const handleRecalculate = () => {
    setCurrentPage("Device");
  }


  useEffect(() => {
    if (currentValue < 0) {
      console.log(currentValue,"currentValue......")
      if (rowDetails["MaxValue"] < 15000) setPrice(1200);
      else if (rowDetails["MaxValue"] >= 15000 && rowDetails["MaxValue"] <= 35000) setPrice(1700);
      else if (rowDetails["MaxValue"] > 35000 && rowDetails["MaxValue"] <= 50000) setPrice(2200);
      else if (rowDetails["MaxValue"] > 50000) setPrice(3000);
    }
  }, [currentValue, rowDetails]);
  
  const handleFormData = (data) => {
    console.log(data,"Fundddd")
    console.log("Received form data func:", data);
    onFormData(data)
    
  };



  if (currentPage === 'pickupPage') {
    return (<Pickup selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue > 0  ? currentValue : DefaultPrice} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} userLogDetails={userLogDetails} onFormData={onFormData}/>);
  }

  if (currentPage === 'Device') {
    return (<Device selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={rowDetails.MaxValue} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} userLogDetails={userLogDetails} onFormData={onFormData}/>);
  }

  if (currentPage === 'loginSignup') {
    return (<LoginSignupPage selectedModel={selectedModel} formattedVariant={formattedVariant} currentValue={currentValue > 0  ? currentValue : DefaultPrice} warrantyStatus={warrantyStatus} rowDetails={rowDetails} answers={answers} FixedPrice={FixedPrice} deviceAnswers={deviceAnswers} ScreenselectedOptions={ScreenselectedOptions} DefectsselectedOptions={DefectsselectedOptions} functionalSelectedOptions={functionalSelectedOptions} accSelectedOptions={accSelectedOptions} userLogDetails={userLogDetails} onFormData={onFormData}/>);
  }

  return (
    <Container>
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={6}>
      <Card sx={{ boxShadow: 4, borderRadius: '12px', overflow: 'hidden', transition: '0.3s ease-in-out' }}>
      <CardContent>
      <Grid container spacing={2}>              {/* Left Side */}
              <Grid item xs={12} md={6}>
              <CardActionArea sx={{ display: 'flex', alignItems: 'center',justifyContent:"space-around", transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}>

                    <CardMedia
                      component="img"
                      image={rowDetails[" Image URL"] || '/path/to/default/image.jpg'}
                      alt="Device Image"
                      // sx={{ width: 150, height: 300, mb: 2, objectFit: 'cover', borderRadius: 2 }}
                      sx={{ width: 100, height: 300, objectFit:'contain', borderRadius: '8px' }}
                    />
                    <Box sx={{ ml: 2 }}>

                     
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center',whiteSpace:"nowrap" }}>
                        {`${rowDetails["Brand name"]} ${rowDetails["Model"]} (${formattedVariant})` || 'Phone Name'}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1, color: 'black' }}>Selling Price :</Typography>
                      <Typography variant="h4" sx={{ mt: 1, color: 'red' }}>
                        {FixedPrice ? `₹ ${FixedPrice}` : `₹ ${currentValue > 0 ? currentValue : DefaultPrice}`}
                      </Typography>
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ mt: 2, textDecoration: 'underline', padding: 0, minWidth: 'auto' }}
                        onClick={handleRecalculate}
                      >
                        Recalculate
                      </Button>

                      <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={4}>
                        <Box display="flex" alignItems="center" justifyContent="end">
                        <img
                              src={payment_icon}
                              style={{ maxWidth: '30px', marginRight: '8px' }}
                              alt="Payment Icon"
                            />
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Fast Payments</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                        <img
                              src={safe}
                              style={{ maxWidth: '30px', marginRight: '8px' }}
                              alt="Safe Icon"
                            />
                         
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Free Pickup</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" alignItems="center">
                        <img
                              src={cos2}
                              style={{ maxWidth: '30px', marginRight: '8px' }}
                              alt="Pickup Icon"
                            />
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>100% Safe</Typography>
                        </Box>
                      </Grid>
                      </Grid>
                      </Box>
                      </CardActionArea>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      <Grid item xs={12} md={6}>
      <Card sx={{ boxShadow: 4, borderRadius: '12px', overflow: 'hidden' }}>
      <CardContent>
           
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Price Summary</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Base Price: {FixedPrice ? `₹ ${FixedPrice}` : `₹ ${currentValue > 0 ? currentValue : DefaultPrice}`}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Pickup Charges: Free <span style={{ textDecoration: 'line-through' }}>₹ 100</span></Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">Processing Fee: ₹0</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Total Amount: {FixedPrice ? `₹ ${FixedPrice}` : `₹ ${currentValue > 0 ? currentValue : DefaultPrice}`}</Typography>
              {/* <Divider sx={{ my: 2 }} /> */}
             
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'lightgreen', color: 'white' }}
              endIcon={<ArrowForwardIcon />}
              onClick={handleContinue}
            >
              Sell Now
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  </Container>
  );
};

export default SellNow;
